.p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-column-filter-overlay, .p-multiselect .p-multiselect-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    min-width: 12.5rem;
}
.p-multiselect .p-multiselect-panel {
    max-width: max-content;
}
.p-column-filter-overlay {
    max-width: 15rem;
    right: 0;
}
.p-multiselect .p-multiselect-panel {
    padding: 10px;
}
.p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    display: flex;
    gap: 5px;
}
.p-checkbox {
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    vertical-align: bottom;
    position: relative;
    width: 20px;
    height: 20px;    
}
.p-checkbox .p-checkbox-box {
    border: 2px solid #abcff3;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox-box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}
.p-multiselect-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
}
.p-multiselect-filter-icon {
    right: 5px;
}
.p-multiselect-close {
    left: 5px;
}
.p-column-filter-overlay-menu .p-column-filter-operator, .p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
}
.p-dropdown:not(.filter-document-direction-dropdown), .p-multiselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    height: 25px;
}
.p-dropdown {
    align-items: center;
}
.p-dropdown, .p-multiselect {
    height: 100% !important;
}
.p-dropdown .p-dropdown-trigger:not(.filter-document-direction-dropdown), .p-multiselect-trigger {
    background: transparent;
    color: #6c757d;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.p-dropdown .p-dropdown-label, .p-multiselect-label {
    background: transparent;
    border: 0 none;
    padding: 4px;
    font-size: 14px;
    white-space: normal !important;
}
.p-inputtext {
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
    border: 1px solid #ced4da;


    height: 25px; // TODO MAYBE DELETE
}
.p-dropdown-panel:not(.filter-document-direction-dropdown) {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
    padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #495057;
    background: #E3F2FD;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}
.p-dropdown-item {
    cursor: pointer;
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
}
.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: #2196F3;
}
.p-inputtext:enabled:hover {
    border-color: #2196F3;
}
.p-fluid .p-button {
    width: 100%;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.5rem 1rem;
}
.p-button {
    color: #ffffff;
    background: #2196F3;
    border: 1px solid #2196F3;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}
.p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
}
.p-button.p-button-text {
    background-color: transparent;
    color: #2196F3;
    border-color: transparent;
}
.p-column-filter-add-button {
    justify-content: center;
}
.p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
}
.p-button .p-button-icon-left {
    margin-right: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1rem;
    & button:first-child {
        margin-right: 1%;
    }
}
.p-button.p-button-outlined {
    background-color: transparent;
    color: #2196F3;
    border: 1px solid;
}
.p-button.p-button-text:enabled:hover {
    background: rgba(33, 150, 243, 0.04);
    color: #2196F3;
    border-color: transparent;
}
.p-calendar .p-datepicker {
    min-width: 100%;
}
.p-datepicker:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-datepicker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #ffffff;
}
.p-datepicker .p-datepicker-header {
    padding: 0 0.5rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0;
}
.p-datepicker table th > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.8rem;
    height: 1.8rem;
    font-size: 14px;
}
.p-datepicker td > span {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}
.p-datepicker table td > span {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 14px;
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker-header .p-datepicker-title {
    margin: 0 auto;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
}
.p-datepicker table td.p-datepicker-today > span {
    background: #ced4da;
    color: #495057;
    border-color: transparent;
}
.p-datepicker table td > span.p-highlight {
    color: #495057;
    background: #E3F2FD;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #e9ecef;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #495057;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    font-weight: 600;
    padding: 0.5rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #2196F3;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
}
.p-monthpicker-month {
    width: 33.3%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #495057;
    background: #E3F2FD;
}
.p-datepicker-other-month {
    color: #aeb1b1;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #D32F2F;
    border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
    background: rgba(211, 47, 47, 0.04);
    border-color: transparent;
    color: #D32F2F;
}
.p-datepicker .p-yearpicker {
    margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: 0.5rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #495057;
       background: #E3F2FD;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #E3F2FD;
    color: #495057;
}
.p-column-filter-buttonbar .p-button {
    width: 90px !important;
}
p-splitter {
    overflow: hidden;
}
.p-splitter-gutter {
    width: 5px !important;
    border-left: 1px solid #747474;
}
.p-splitter-panel {
    min-width: 20px;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #dee2e6;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow, .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-right-color: #495057;
}
.p-tooltip .p-tooltip-text {
    background: #495057;
    color: #ffffff;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #495057;
}

.p-tooltip-top .p-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -0.25rem;
    border-width: 0.25em 0.25em 0;
}

.profile-tooltip {
    top: 0 !important;
}

.p-tooltip-menu {
    left: 40px !important;
}
.p-tooltip {
    // max-width: 30rem !important;
    max-width: 25rem !important;
}
.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.2s;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #f0f0f0;
    color: #343a40;
    padding: 13px 10px 13px 20px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    max-height: 100%;
}

.p-dialog {
	overflow: auto;
    max-height: 100% !important;
}

.p-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px
}

.p-card {
    background: #ffffff;
    color: #495057;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 12px;

    .p-card-body {
        padding: 1.25rem;
    }
}

.p-progressbar {
    position: relative;
    overflow: hidden;
    border: 0 none;
    height: 1.5rem;
    background: #dee2e6;
    border-radius: 6px;
}

.p-progressbar .p-progressbar-value {
    margin: 0;
    background: #3B82F6;
}

.p-progressbar-determinate .p-progressbar-value-animate {
    transition: width 1s ease-in-out;
}

.p-progressbar-determinate .p-progressbar-value {
    height: 100%;
    width: 0%;
    position: absolute;
    display: none;
    border: 0 none;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.selected-archive-item .p-card {
    border: 2px solid #2966CA;
}

.selected-item .p-card {
    background: #2966CA;
    .p-card-header .header-name, .count-block .counts button, .p-card-content p:not(.light-text) {
        color: white;
    }
    .count-block .counts button {
        border: 1px solid white;
    }
    .count-block .counts button:hover {
        color: rgb(148, 147, 147);
        border-color: #949393;
    }
}

.p-progressbar {
    height: 8px;
}

.p-skeleton {
    background-color: #dee2e6;
}

.p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-toast {
    position: fixed;
    width: 25rem;
    opacity: 0.9;
}

.p-toast-top-right {
    top: 20px;
    right: 20px;
}

.p-toast .p-toast-message.p-toast-message-error {
    background: #FFCDD2;
    border: solid #e60017;
    border-width: 0 0 0 6px;
    color: #73000c;
}

.p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

.p-toast-message-content {
    display: flex;
    align-items: flex-start;
}

.p-toast .p-toast-message .p-toast-message-content {
    padding: 1rem;
    border-width: 0 0 0 6px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
}

.p-toast-message-text {
    flex: 1 1 auto;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #73000c;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem;
}

.p-orderlist-controls {
	display: none !important;
}

.p-component .p-orderlist-list-container {
    width: calc(90% - 10px);
    min-width: calc(90% - 30px);
}

.p-picklist {
    gap: 10px;
}

.p-picklist .p-picklist-list {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 0.5rem 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.5rem 1rem;
    margin: 0;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    & p {
        margin: 0;
    }
}

.p-picklist-buttons {
    display: none !important; // TODO delete if view button
    margin: 10px;
    gap: 5px;
    button {
        padding: 8px;
    }
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    background: #80808024 !important;
    border: 1px dotted;
}

.p-ink {
    display: block;
    position: absolute;
    background: rgba(255,255,255,.5);
    border-radius: 100%;
    transform: scale(0)
}

.p-picklist .p-picklist-header {
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    padding: 0.5rem;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-picklist-title {
    display: flex;
    justify-content: center;
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #64748B;
    border-color: transparent;
}

.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
    background: rgba(100, 116, 139, 0.04);
    border-color: transparent;
    color: #64748B;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #22C55E;
    border: 1px solid #22C55E;
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #64748B;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #EF4444;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
    background: rgba(239, 68, 68, 0.04);
    color: #EF4444;
    border: 1px solid;
}

.p-ink-active {
    animation: ripple .4s linear
}

.p-picklist-item.cdk-drag-preview {
    padding: 0.75rem 1.25rem;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 0 none;
    color: #495057;
    background: #ffffff;
    margin: 0;
}

.p-picklist-item:not(.cdk-drag-disabled) {
    cursor: grabbing !important;
    z-index: 2300 !important;
}

.p-picklist-list.cdk-drop-list-dragging {
    cursor: grabbing !important;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #22C55E;
    border: 1px solid #22C55E;
}

.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #22C55E;
    border: 1px solid;
}

.pi-download {
    font-weight: bold !important;
}
.h3r .p-dropdown-items-wrapper .p-dropdown-items .p-element .p-dropdown-item {
    height: 3rem;
    align-items: center;
    display: flex;
}
.p-dialog-title {
    color: #000000;
}

.p-dialog-header-close-icon {
    font-size: 18px;
}

.custom-partner-info {
	.p-dialog-header {
        .p-dialog-title {
            text-overflow: ellipsis !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient:vertical !important;
            overflow: hidden !important;
        }
	}
}

.p-inputswitch {
    position: relative;
    display: inline-block;
    -webkit-user-select: none;
    user-select: none;
    height: 25px;
    width: 46px;
}

.p-inputswitch-slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #3745E8;
}

.p-inputswitch .p-inputswitch-slider {
    background: #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 30px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    transition-duration: 0.2s;
}

.p-inputswitch-slider:before {
    position: absolute;
    content: "";
    top: 50%;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #495057;
}

.p-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.p-tooltip-bottom .p-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -.25rem;
    border-width: 0 .25em .25rem;
}
 
.full-screen {
	width: 100vw;
	height: 100vh;
	background-color: #F1F3F5;
}
