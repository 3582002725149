/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/roboto/font.css";
@import "styles/primeng.scss";

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(assets/fonts/material/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

:root {
	margin: 0;
	padding: 0;
	height: 100%;

	--accent-color: rgb(0, 102, 209);
	--grey-bg: rgb(242, 246, 246);
	--orange-action: rgb(253, 181, 9);
	--green-action: rgb(17, 196, 79);
	--blue-action: rgb(0, 73, 118);
	--deep-green: rgb(0, 165, 80);
	--outline-color: #639efe;
	--error-color: rgb(252, 121, 95);
	--header-bg: rgb(16, 159, 156);
	--header-bg-ctt: rgb(15, 7, 64);
	--header-bg-neutral: rgb(255, 255, 255);

	--orange-action-me: #f07d29;
	--header-bg-me: rgb(46, 46, 72);
}

:disabled {
	opacity: 0.8;
}

input:disabled {
	background: #e8e8e8;
	cursor: default !important;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background-color: #fff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
	background: rgb(116, 116, 116);
	border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #253861;
}

* {
	// scrollbar-width: thin;
}

:host ::ng-deep .p-column-filter-overlay {
	background: #ffffff;
	color: #495057;
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	min-width: 12.5rem;
}

.content-editable-tag:focus[contenteditable="true"] {
	padding: 3px;
	outline: none;
}

.content-editable-tag {
	display: flex;
	border: 1px solid grey;
	padding: 3px;
	border-radius: 3px;
	width: 99%;
	background: white;
}

.editable-block {
	display: flex;
	align-items: center;
	gap: 3px;
	.pi {
		color: #a8a8a8;
	}
	label {
		padding-bottom: 0;
	}
}
