:root {

	button.action,
	button.action-save {
		background-color: var(--green-action);
	}

	button.action:disabled,
	button.action-save:disabled {
		background-color: #6dd591;
	}

	app-header {
		background-color: var(--header-bg-ctt);
	}
}
